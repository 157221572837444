<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'巡检人员'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="根据账号查询"
          v-model="searchQuery.account"
          class="searchInput"
        >
          <template slot="prepend">巡检账号</template>
        </el-input>

        <el-input
          clearable
          placeholder="根据巡检人查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">巡检人</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritPatrolPerson",
      searchVal: "",
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [0, 0, 0, 0],
        thirdSoltSpan: [17, 17, 17, 17],
      },
      searchQuery: { name: "", account: "" },
      props: [
        {
          label: "账号",
          prop: "account",
          align: "center",
          width: "250px",
        },

        {
          label: "密码",
          prop: "password",
          align: "center",
          width: "200px",
        },
        {
          label: "巡检人",
          prop: "name",
          align: "center",
        },

        {
          label: "手机",
          prop: "mobilePhone",
          align: "center",
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "账号",
          prop: "account",
          type: "input",
        },

        {
          label: "密码",
          prop: "password",
          type: "input",
        },
        {
          label: "巡检人",
          prop: "name",
          type: "input",
        },

        {
          label: "手机",
          prop: "mobilePhone",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
